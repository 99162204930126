<template>
  <div>
    <div class="pa-4 d-flex align-center primaryColor fsize20 font-weight-bold ">
      Add Sovereign Gold Bonds (SGB)
    </div>
    <div class="mt-1">
      <v-form ref="form" v-model="formvalid" @keypress.enter="addOrEditSgb()" lazy-validation>
        <v-card class="mx-4 pa-5 rounded-lg">
          <v-row class="ma-0">
            <v-col cols="12" sm="12" md="6" lg="3">
              <span class="fsize14 font-weight-bold primaryColor">SGB Series Details</span>
            </v-col>
            <v-col cols="12" sm="12" md="6" lg="5">
              <div class="pb-1 secondaryColor fsize14">Title</div>
              <v-text-field dense height="40px" placeholder="Enter the Title" outlined v-model="title"></v-text-field>
              <div class="pb-1 secondaryColor fsize14">Series Name</div>
              <v-text-field dense height="40px" placeholder="Enter the Parent Organization" outlined v-model="seriesName"></v-text-field>
              <div class="pb-1 secondaryColor fsize14">Status</div>
              <v-select v-model="status" outlined dense placeholder="Select the status" :items="['Open','Upcoming']" menu-props="offset-y"></v-select>
            </v-col>
            <v-col cols="12" sm="12" md="6" lg="4"></v-col>
          </v-row>
          <div v-if="status != 'Upcoming'">
            <v-divider></v-divider>
            <v-row class="ma-0 mt-6">
              <v-col cols="12" sm="12" md="6" lg="3">
                <span class="fsize14 font-weight-bold primaryColor">Result Details</span>
              </v-col>
              <v-col cols="12" sm="12" md="6" lg="5">
                <v-row class="d-flex ma-0">
                  <v-col class="pa-0 mr-4">
                    <div class="pb-1 secondaryColor fsize14">Offer Open Date</div>
                    <v-menu v-model="openDatemenu" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="auto">
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field :disabled="status == 'Upcoming'" v-model="computedDateFormatted" outlined persistent-hint placeholder="Enter Offer Open Date" append-icon="mdi-calendar" readonly dense v-bind="attrs" v-on="on"></v-text-field>
                      </template>
                      <v-date-picker v-model="openDate" :show-current="true" no-title @input="openDatemenu = false"></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col class="pa-0">
                    <div class="pb-1 secondaryColor fsize14">Offer Close Date</div>
                    <v-menu v-model="closeDateMenu" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="auto">
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field :disabled="status == 'Upcoming'" v-model="computedDateFormatted1" outlined persistent-hint placeholder="Enter Offer Close Date" append-icon="mdi-calendar" readonly dense v-bind="attrs" v-on="on"></v-text-field>
                      </template>
                      <v-date-picker v-model="closeDate" :show-current="true" no-title @input="closeDateMenu = false"></v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>

                <div class="pb-1 secondaryColor fsize14">Price per Gram</div>
                <v-text-field :disabled="status == 'Upcoming'" dense height="40px" placeholder="Enter the Price per Gram" outlined v-model="perGramPrice">
                </v-text-field>

                <div class="pb-1 secondaryColor fsize14">Annual Interest Payout</div>
                <v-text-field :disabled="status == 'Upcoming'" dense height="40px" placeholder="Enter the Minimum Invest" outlined v-model="annualInterest">
                </v-text-field>

                <div class="pb-1 secondaryColor fsize14">Maturity Date</div>
                <v-menu v-model="maturityDateMenu" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="auto">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field :disabled="status == 'Upcoming'" dense height="40px" placeholder="Enter the Maturity Date" outlined v-model="computedDateFormatted2" append-icon="mdi-calendar" v-bind="attrs" v-on="on">
                    </v-text-field>
                  </template>
                  <v-date-picker v-model="maturityDate" :show-current="true" no-title @input="maturityDateMenu = false"></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" sm="12" md="6" lg="4"></v-col>
            </v-row>
          </div>
        </v-card>

        <div class="mt-5 pa-4 d-flex justify-end">
          <v-btn height="40" width="96" depressed outlined class="text-capitalize" @click="$router.push('/sgb')">Cancel</v-btn>
          <v-btn @click="addOrEditSgb()" height="40" width="96" color="primary" depressed class="ml-4 white--text text-capitalize">
            Save
          </v-btn>
        </div>
      </v-form>
    </div>
  </div>
</template>

<script>
import service from "../js/httpservice";
import { mapGetters, mapState } from "vuex";
export default {
  data() {
    return {
      title: "",
      seriesName: "",
      status: "",
      //
      openDatemenu: false,
      openDate: "",
      closeDateMenu: false,
      closeDate: "",
      perGramPrice: "",
      annualInterest: "",
      maturityDateMenu: false,
      maturityDate: "",
      formvalid: false,
      sgbId: 0,

      // rules for validation
      minInvestRules: [(v) => !!v || "Minimum Invest is required"],
    };
  },
  methods: {
    async addOrEditSgb() {
      this.$refs.form.validate();
      let jsonObj = {
        sgbId: this.sgbId,
        tittle: this.title,
        seriesName: this.seriesName,
        status: this.status,
        offOpenDate: this.status != "Upcoming" ? this.openDate : null,
        offCloseDate: this.status != "Upcoming" ? this.closeDate : null,
        pricePerGm: this.status != "Upcoming" ? this.perGramPrice : null,
        annualInterestPayout:
          this.status != "Upcoming" ? this.annualInterest : null,
        maturityDate: this.status != "Upcoming" ? this.maturityDate : null,
      };
      await this.$store.dispatch("sgb/insertSgb", jsonObj);
    },

    getSgbList(sgbId) {
      service.getSgbData().then((response) => {
        if (response.status == 200 && response.data.message == "Success") {
          for (let item of response.data.result) {
            if (sgbId == item.sgbId) {
              this.title = item.tittle;
              this.seriesName = item.seriesName;
              this.status = item.status;
              this.openDate = item.offOpenDate
                ? item.offOpenDate.split(" ")[0]
                : null;
              this.closeDate = item.offCloseDate
                ? item.offCloseDate.split(" ")[0]
                : null;
              this.perGramPrice = item.pricePerGm;
              this.annualInterest = item.annualInterestPayout;
              this.maturityDate = item.maturityDate;
            }
          }
        }
      });
    },

    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${year}-${month}-${day}`;
    },
  },
  computed: {
    computedDateFormatted() {
      return this.formatDate(this.openDate);
    },
    computedDateFormatted1() {
      return this.formatDate(this.closeDate);
    },
    computedDateFormatted2() {
      return this.formatDate(this.maturityDate);
    },
  },
  mounted() {
    var checkId = JSON.parse(localStorage.getItem("addOrEditSgb"));
    !!checkId && checkId != 0 ? this.getSgbList(checkId) : "";
    !!checkId ? (this.sgbId = checkId) : "";
  },
};
</script>

<style>
</style>
