<template>
  <div>
    <v-row class="ma-0 pa-4 align-center">
      <v-col cols="2" class="pa-0">
        <div class="d-flex">
          <h3 width="100" height="24">IPO</h3>
          <v-progress-circular class="ml-2" indeterminate v-if="loading" size="18" :width="2" color="black"></v-progress-circular>
        </div>
      </v-col>
      <v-col class="pa-0" cols="1"></v-col>
      <v-col cols="6" class="pa-0" style="height: 40px">
        <v-text-field single-line dense outlined height="32px" v-model="search" prepend-inner-icon="mdi-magnify" placeholder="Search"></v-text-field>
      </v-col>
      <v-col class="pa-0" cols="1"></v-col>

      <v-col cols="2" class="pa-0 d-flex justify-end">
        <v-btn depressed color="align-center justify-center  text-none pa-0 bgr_clr_btn_save" min-width="155px" @click="addOrEditIpo('0')">
          Add IPO
        </v-btn>
      </v-col>

    </v-row>
    <v-card class="ma-4" >
      <v-data-table v-if="ipoData.length != 0" :headers="headers" v-bind:items="ipoData" :search="search" :value="ipoData" class="elevation-1" id="table" item-key="name" single-select>
        <template v-slot:item="props">
          <tr>
            <td>{{ props.item.ipoName }}</td>
            <td>{{ props.item.bbFromDate ? props.item.bbFromDate.split(' ')[0] : '' }}</td>
            <td>{{ props.item.bbToDate ? props.item.bbToDate.split(' ')[0] : '' }}</td>
            <td>{{ props.item.minInvest }}</td>
            <td>{{ props.item.priceRangeFrom }}</td>
            <td>{{ props.item.priceRangeTo }}</td>
            <!-- <td>{{ props.item.foundedOn }}</td> -->
            <td>{{ props.item.issueSize }}</td>
            <td>{{ props.item.status }}</td>
            <!-- <td>{{ props.item.aboutCompany }}</td> -->
            <!-- <td>{{ props.item.mdName }}</td> -->
            <td>{{ props.item.parentOrg }}</td>
            <td class="text-center">
              <v-icon class="mr-4" @click="addOrEditIpo(props.item.ipoId)"> mdi-pencil </v-icon>
              <v-icon @click="deleteIpo(props.item.ipoId)"> mdi-delete </v-icon>
            </td>
          </tr>
        </template>
      </v-data-table>
      <div v-else>
        <div class="text-center pa-5">
          <v-icon class="display-2 pb-4">mdi-thought-bubble-outline</v-icon>
          <div class="">Data Not Found</div>
        </div>
      </div>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      newsList: [],
      search: "",
      headers: [
        { text: "IPO Name",value:"ipoName", align: "start", sortable: true },
        { text: "Bidding From Date", value: "bbFromDate", sortable: false },
        { text: "Bidding To Date", value: "bbToDate", sortable: false },
        { text: "Minimum Invest", value: "minInvest", sortable: false },
        { text: "Price Range From", value: "priceRangeFrom", sortable: false },
        { text: "Price Range To", value: "priceRangeTo", sortable: false },
        // { text: "FoundedYear", value: "headLine", sortable: false },
        { text: "issueSize", value: "issueSize", sortable: false },
        { text: "Status", value: "status", sortable: false },
        // { text: "About Company", value: "description", sortable: false },
        // { text: "Managing Director", value: "industry", sortable: false },
        { text: "Organization", value: "parentOrg", sortable: false },
        { text: "Actions", align: "center", value: "", sortable: false },
      ],
    };
  },
  methods: {
    addOrEditIpo(ipoId) {
      localStorage.setItem("addOrEditIpo", JSON.stringify(ipoId));
      this.$router.push("/addOrEditIpo");
    },
    deleteIpo(ipoId) {
      this.$store.dispatch("ipo/deleteIpo",ipoId);
    },
  },
  computed: {
    ...mapGetters("ipo", {
      ipoData: "getIpoData",
    }),
    ...mapGetters({
      loading: "getLoading",
    }),
  },
  mounted() {
    this.$store.dispatch("ipo/getIpoData");
  },
};
</script>

<style>
</style>