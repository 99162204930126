<template>
  <div>
    <v-row class="ma-0 pa-4 align-center">
      <v-col cols="2" class="pa-0">
        <h3 width="100" height="24" class=".text-lg-h6">Holidays</h3>
      </v-col>
      <v-col class="pa-0" cols="1"></v-col>
      <v-col cols="6" class="pa-0" style="height: 40px">
        <v-text-field single-line dense outlined height="32px" prepend-inner-icon="mdi-magnify" placeholder="Search" v-model="search"></v-text-field>
      </v-col>
      <v-col class="pa-0" cols="1"></v-col>

      <v-col cols="2" class="pa-0 d-flex justify-end">
      </v-col>
    </v-row>

    <v-data-table :headers="headers" v-bind:items="this.holidayList" :search="search" :value="this.holidayList" class="elevation-1  mx-5 my-5" id="table" item-key="name" single-select>

      <template v-slot:item="props">
        <tr>

          <td>{{ props.index + 1 }}</td>
          <td>{{ props.item.holidayDay }}</td>
          <td>{{ props.item.holidayDate }}</td>
          <td>{{ props.item.holidayName }}</td>
        </tr>
      </template>
    </v-data-table>

    <v-dialog v-model="dialog" width="500">

      <v-card>
        <v-card-title class="text-h5">Add a new Scrip</v-card-title>
        <div class="ma-0 row d-flex justify-space-around">
          <div class=" mt-4" style="width: 180px">

            <v-text-field dense height="40px" label="Scrip Name" autocomplete="off" outlined>
            </v-text-field>
          </div>
          <div class=" mt-4">

            <v-text-field dense height="40px" label="Stock Name" class="primaryColor fsize14" maxlength="50" autocomplete="off" outlined>
            </v-text-field>
          </div>
        </div>

        <div class="ma-0 row d-flex justify-space-around">
          <div class=" mt-4">

            <v-text-field dense height="40px" label="Company Name" class="primaryColor fsize14" maxlength="50" autocomplete="off" outlined>
            </v-text-field>
          </div>
          <div class=" mt-4">

            <v-text-field dense height="40px" label="token" class="primaryColor fsize14" maxlength="50" autocomplete="off" outlined>
            </v-text-field>
          </div>
        </div>

        <div class="row mx-0 my-4 d-flex justify-space-around">
          <div style="width: 180px">
            <v-menu v-model="menu" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="auto">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field v-model="computedDateFormatted" outlined persistent-hint prepend-inner-icon="mdi-calendar" readonly placeholder="DD-MM-YYYY" dense v-bind="attrs" v-on="on"></v-text-field>
              </template>
              <v-date-picker v-model="date" :show-current="true" no-title @input="menu = false"></v-date-picker>
            </v-menu>

          </div>
          <div>

            <v-text-field dense height="40px" label="Stock Name" class="primaryColor fsize14" maxlength="50" autocomplete="off" outlined>
            </v-text-field>
          </div>
        </div>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialog = false">
            I accept
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import service from "../js/httpservice";
export default {
  data() {
    return {
      holidayList: [],
      headers: [
        {
          text: "S.NO",
          align: "start",
          sortable: false,
        },
        { text: "holidayDay", value: "holidayDay", sortable: false },
        { text: "holidayDate", value: "holidayDate", sortable: false },
        { text: "holidayName", value: "holidayName", sortable: false },
      ],
      search: "",
      menu: false,
      date: "",
      dialog: false,
    };
  },
  methods: {
    getList() {
      service.getHolidayList({}).then((resp) => {
        this.holidayList = resp.data.result;
      });
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}-${month}-${year}`;
    },
  },
  computed: {
    computedDateFormatted() {
      return this.formatDate(this.date);
    },
  },
  mounted() {
    this.getList();
  },
};
</script>

<style>
</style>