<template>
  <div class="ma-4">
    <div class="d-flex justify-space-between">
      <div class="d-flex align-center">
        <v-breadcrumbs class="pa-0 mr-5" :items="titles" large></v-breadcrumbs>
        <v-progress-circular class="ml-2" indeterminate v-if="loading" size="18" :width="2" color="black"></v-progress-circular>
      </div>
      <v-col cols="6" class="pa-0" style="height: 40px">
        <v-text-field single-line dense outlined height="32px" v-model="search" prepend-inner-icon="mdi-magnify" placeholder="Search"></v-text-field>
      </v-col>
      <v-btn depressed color="primary" @click="addOrEditSector(0)" class="text-capitalize fsize12">Add Sector Details</v-btn>
    </div>
    
    <v-data-table v-if="sectorMappedList.length != 0 && !loading" :headers="headers" v-bind:items="sectorMappedList" :search="search" :value="sectorMappedList" class="elevation-1 my-2" id="table" item-key="name" single-select>
      <template v-slot:item="props">
        <tr>
            <td>{{props.index+1}}</td>
            <td>{{props.item.stockName}}</td>
            <td>{{props.item.scripName}}</td>
            <td>{{props.item.companyName}}</td>
            <td>{{props.item.exch}}</td>
            <td>{{props.item.token}}</td>
            <!-- <td class="text-center">
              <v-icon @click="deleteSectorMapping(item)"> mdi-delete </v-icon>
            </td> -->
        </tr>
      </template>
    </v-data-table>
    
        <div v-else-if="!loading">
      <div class="text-center pa-5">
        <v-icon class="display-2 pb-4">mdi-thought-bubble-outline</v-icon>
        <div class="">Data Not Found</div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data: () => ({
    titles: [
      { text: "Sector", href: "/#/sector", disabled: false },
      { text: "content", disabled: true },
    ],
    headers: [
        { text: "S.NO", align: "start", sortable: false },
        { text: "Stock Name", value: "stockName", sortable: false },
        { text: "Script Name", value: "scripName", sortable: false },
        { text: "Company Name", value: "companyName",sortable: false,},
        { text: "Exchange", value: "exch",sortable: false,},
        { text: "Token", value: "token",sortable: false,},
        // {
        //   text: "Actions",
        //   align: "center",
        //   value: "sectorId",
        //   sortable: false,
        // },
      ],
      search: "",
  }),
  computed: {
    ...mapGetters({
      loading: "getLoading",
    }),
    ...mapGetters("sector", {
      sectorMappedList: "getSectorMappedData",
    }),
  },
  methods: {
    addOrEditSector(sectorId) {
      this.$router.push("/addOrEditSectorContent");
    },
    async deleteSectorMapping(val) {
      let jsonObj = {
        sectorId: val.sectorId,
        token: val.token,
      };
      await this.$store.dispatch("sector/deleteSectorMapping", jsonObj);
      this.getMappedData();
    },
    getMappedData() {
      let sectorId = localStorage.getItem("sectorId");
      this.$store.dispatch("sector/getSectorMappedData", {
        sectorId: sectorId,
      });
    },
  },
  created() {
    this.getMappedData();
  },
};
</script>