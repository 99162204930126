<template>
  <div>
    <v-row class="ma-0 pa-4 align-center">
      <v-col cols="2" class="pa-0">
        <h3 width="100" height="24" class=".text-lg-h6">News</h3>
      </v-col>
      <v-col class="pa-0" cols="1"></v-col>
      <v-col cols="6" class="pa-0" style="height: 40px">
        <v-text-field single-line dense outlined height="32px" v-model="search" prepend-inner-icon="mdi-magnify" placeholder="Search"></v-text-field>
      </v-col>
      <v-col class="pa-0" cols="1"></v-col>

      <v-col cols="2" class="pa-0 d-flex justify-end">
        <v-btn depressed color="align-center justify-center  text-none pa-0 bgr_clr_btn_save " min-width="155px" @click="addOrEditNews('0')">
          Add News
        </v-btn>
      </v-col>

    </v-row>
    <v-card class="ma-4">
      <v-data-table v-if="newsList.length != 0" :headers="headers" v-bind:items="newsList" :search="search" :value="newsList" class="elevation-1 my-5" id="table" item-key="name" single-select>
        <template v-slot:item="props">
          <tr>
            <td>{{ props.index + 1 }}</td>
            <td>{{ props.item.companyName }}</td>
            <!-- <td>{{ props.item.scripName }}</td> -->
            <!-- <td>{{ props.item.stockName }}</td> -->
            <td>{{ props.item.source }}</td>
            <!-- <td>{{ props.item.token }}</td> -->
            <td>{{ props.item.headLine }}</td>
            <td>{{ props.item.subject }}</td>
            <td>{{ props.item.newsType }}</td>
            <!-- <td>{{ props.item.description }}</td> -->
            <!-- <td>{{ props.item.industry }}</td> -->
            <td>{{ props.item.date.split(' ')[0] }}</td>
            <td>
              <v-icon medium @click="addOrEditNews(props.item.newsId)"> mdi-pencil </v-icon>
            </td>
            <td>
              <v-icon medium @click="deleteNews(props.item.newsId)"> mdi-delete </v-icon>
            </td>
          </tr>
        </template>
      </v-data-table>
      <div v-else>
        <div class="text-center pa-5">
          <v-icon class="display-2 pb-4">mdi-thought-bubble-outline</v-icon>
          <div class="">Data Not Found</div>
        </div>
      </div>
    </v-card>
  </div>
</template>

<script>
import service from "../js/httpservice";
export default {
  data() {
    return {
      newsList: [],
      search: "",
      headers: [
        { text: "S.NO", align: "start", sortable: false },
        { text: "Company Name", value: "companyName", sortable: true },
        // { text: "Scrip Name", value: "scripName", sortable: false },
        // { text: "Stock Name", value: "stockName", sortable: false },
        { text: "Source", value: "source", sortable: false },
        // { text: "Token", value: "token", sortable: false },
        { text: "HeadLine", value: "headLine", sortable: false },
        { text: "Subject", value: "subject", sortable: false },
        { text: "News Type", value: "newsType", sortable: false },
        // { text: "Description", value: "description", sortable: false },
        // { text: "Industry", value: "industry", sortable: false },
        { text: "Date", value: "date", sortable: false },
        { text: "", value: "", sortable: false },
        { text: "", value: "", sortable: false },
      ],
    };
  },
  methods: {
    getList() {
      service.getNewsList({}).then((resp) => {
        if (resp.status == 200 && resp.data.stat == "Ok") {
          this.newsList = resp.data.result;
        } else {
          this.newsList = [];
        }
      });
    },

    addOrEditNews(key) {
      localStorage.setItem("addOrEditNews", JSON.stringify(key));
      this.$router.push("/addOrEditNews");
    },

    deleteNews(key) {
      let jsonobj = {
        newsId: key,
      };
      service.deleteNews(jsonobj).then((resp) => {
        if (resp.status == 200) {
          this.getList();
        }
      });
    },
  },
  mounted() {
    this.getList();
  },
};
</script>

<style>
</style>