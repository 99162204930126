<template>
  <div>
    <v-row class="ma-0 pa-4 align-center">
      <v-col cols="3" class="pa-0">
        <div class="fsize20 font-weight-bold primaryColor">Sovereign Gold Bonds (SGB)</div>
      </v-col>
      <v-col cols="6" class="pa-0" style="height: 40px">
        <v-text-field single-line dense outlined height="32px" v-model="search" prepend-inner-icon="mdi-magnify" placeholder="Search"></v-text-field>
      </v-col>
      <v-col cols="3" class="pa-0 d-flex justify-end">
        <v-btn min-width="155px" color="#157AFF" depressed @click="addOrEditSgb('0')" class="text-capitalize fsize14 white--text">Add SGB</v-btn>
      </v-col>
    </v-row>
    <v-card class="ma-4">
      <v-data-table v-if="sgbData.length != 0" :headers="headers" v-bind:items="sgbData" :search="search" :value="sgbData" hide-Details class="elevation-1" id="table" item-key="name" single-select>
        <template v-slot:item="props">
          <tr>
            <td>{{ props.item.tittle }}</td>
            <td>{{ props.item.seriesName }}</td>
            <td>{{ props.item.pricePerGm }}</td>
            <td>{{ props.item.offOpenDate ? props.item.offOpenDate.split(' ')[0] : '' }}</td>
            <td>{{ props.item.offCloseDate ? props.item.offCloseDate.split(' ')[0] : '' }}</td>
            <td>{{ props.item.maturityDate ? props.item.maturityDate.split(' ')[0] : '' }}</td>
            <td>{{ props.item.annualInterestPayout }}</td>
            <td class="text-center">
              <v-icon class="mr-4" @click="addOrEditSgb(props.item.sgbId)"> mdi-pencil </v-icon>
              <v-icon @click="deleteSgb(props.item.sgbId)"> mdi-delete </v-icon>
            </td>
          </tr>
        </template>
      </v-data-table>
      <div v-else>
        <div class="text-center pa-5">
          <v-icon class="display-2 pb-4">mdi-thought-bubble-outline</v-icon>
          <div class="">Data Not Found</div>
        </div>
      </div>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data: () => ({
    search: "",
    headers: [
      { text: "Title", value: "tittle", sortable: false },
      { text: "seriesName", value: "seriesName", sortable: false },
      { text: "pricePerGm", value: "pricePerGm", sortable: false },
      { text: "offOpenDate", value: "offOpenDate", sortable: false },
      { text: "offCloseDate", value: "offCloseDate", sortable: false },
      { text: "maturityDate", value: "maturityDate", sortable: false },
      {
        text: "annualInterestPayout",
        value: "annualInterestPayout",
        sortable: false,
      },
      {
        text: "Actions",
        align: "center",
        value: "maturityDate",
        sortable: false,
      },
    ],
  }),
  computed: {
    ...mapGetters("sgb", { sgbData: "getSgbData" }, { loader: "getLoading" }),
  },
  methods: {
    deleteSgb(sgbId) {
      this.$store.dispatch("sgb/deleteSgb", sgbId);
    },
    addOrEditSgb(sgbId) {
      localStorage.setItem("addOrEditSgb", JSON.stringify(sgbId));
      this.$router.push("/addOrEditSgb");
    },
  },
  created() {
    this.$store.dispatch("sgb/getSgbData");
  },
};
</script>

<style>
</style>