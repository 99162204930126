<template>
  <div>
    <div class="pa-4 d-flex align-center primaryColor fsize20 font-weight-bold ">
      Add Initial Public Offering (IPO)
    </div>

    <!-- <div class="mt-4">
      <v-card class="mx-4">
        <v-form ref="form" v-model="formvalid" lazy-validation>
          <v-row class="ma-0 pa-4">
            <v-col>
              <v-text-field dense height="40px" label="Name" outlined v-model="longName" :rules="longNameRules">
              </v-text-field>

              <v-menu v-model="pidFromDate" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="auto">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field :disabled="status == 'Upcoming'" v-model="computedDateFormatted" outlined persistent-hint label="Bidding From Date" :rules="status != 'Upcoming' ? fromDateRules : []" append-icon="mdi-calendar" readonly dense v-bind="attrs" v-on="on"></v-text-field>
                </template>
                <v-date-picker v-model="eventDate" :show-current="true" no-title @input="pidFromDate = false"></v-date-picker>
              </v-menu>

              <v-menu v-model="pidToDate" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="auto">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field :disabled="status == 'Upcoming'" v-model="computedDateFormatted1" outlined persistent-hint label="Bidding To Date" :rules="status != 'Upcoming' ? toDateRules : []" append-icon="mdi-calendar" readonly dense v-bind="attrs" v-on="on"></v-text-field>
                </template>
                <v-date-picker v-model="eventDate1" :show-current="true" no-title @input="pidToDate = false"></v-date-picker>
              </v-menu>

              <v-text-field :disabled="status == 'Upcoming'" dense height="40px" label="Minimum Invest " outlined v-model="minInvest" :rules="status != 'Upcoming' ? minInvestRules : []">
              </v-text-field>
              <v-file-input accept="image/png, image/gif, image/jpeg, image/svg" dense append-icon="mdi-folder-open" v-model="image" label="Upload Image" hide-details="" prepend-icon="" outlined></v-file-input>
            </v-col>
            <v-col>
              <v-select v-model="status" outlined dense label="status" :items="['Open','Upcoming','Recently Closed','Recently Listed']" menu-props="offset-y"></v-select>
              <v-text-field :disabled="status == 'Upcoming'" dense height="40px" label="Price Range From" outlined v-model="priceFrom" :rules="status != 'Upcoming' ? priceFromRules : []">
              </v-text-field>
              <v-text-field :disabled="status == 'Upcoming'" dense height="40px" label="Price Range To" outlined v-model="priceTo" :rules="status != 'Upcoming' ? priceToRules : []">
              </v-text-field>
              <v-text-field dense height="40px" label="Founded Year" outlined v-model="foundedYear">
              </v-text-field>
            </v-col>
            <v-col>
              <v-text-field :disabled="status == 'Upcoming'" dense height="40px" label="Issue Size" outlined v-model="issueSize">
              </v-text-field>
              <v-text-field dense height="40px" label="About Company" outlined v-model="aboutCompany">
              </v-text-field>
              <v-text-field dense height="40px" label="Managing Director" outlined v-model="managingDirector">
              </v-text-field>
              <v-text-field dense height="40px" label="Parent Organization" outlined v-model="parentOrganization">
              </v-text-field>
            </v-col>
          </v-row>
          <v-row class="ma-0 px-4">
            <v-col>

            </v-col>
            <v-col cols="8"></v-col>
          </v-row>
          <div class="row ma-0 pa-4 d-flex justify-end">
            <v-btn depressed outlined class="text-capitalize" @click="$router.push('/ipo')">Cancel</v-btn>
            <v-btn color="primary" depressed class="ml-4 white--text text-capitalize" @click="addOrEditIpo">
              Save
            </v-btn>
          </div>
        </v-form>
      </v-card>
    </div> -->
    <div class="mt-1">
      <v-form ref="form" v-model="formvalid" lazy-validation>
        <v-card class="mx-4 pa-5 rounded-lg">
          <v-row class="ma-0">
            <v-col cols="12" sm="12" md="6" lg="3">
              <span class="fsize14 font-weight-bold primaryColor">Company Details</span>
            </v-col>
            <v-col cols="12" sm="12" md="6" lg="5">
              <div class="pb-1 secondaryColor fsize14">Company Name</div>
              <v-text-field dense height="40px" placeholder="Enter the Company Name" outlined v-model="longName" :rules="longNameRules"></v-text-field>
              <div class="pb-1 secondaryColor fsize14">Parent Organisation Name</div>
              <v-text-field dense height="40px" placeholder="Enter the Parent Organization" outlined v-model="parentOrganization"></v-text-field>
              <v-row class="d-flex ma-0">
                <v-col class="pa-0 mr-4">
                  <div class="pb-1 secondaryColor fsize14">Founded Year</div>
                  <v-text-field dense height="40px" placeholder="Enter the Founded Year" outlined v-model="foundedYear">
                  </v-text-field>
                </v-col>
                <v-col class="pa-0">
                  <div class="pb-1 secondaryColor fsize14">Upload Logo</div>
                  <v-file-input accept="image/png, image/gif, image/jpeg, image/svg" dense append-icon="mdi-folder-open" v-model="image" placeholder="Upload Logo" hide-details="" prepend-icon="" outlined></v-file-input>
                </v-col>
              </v-row>
              <div class="pb-1 secondaryColor fsize14">Managing Director</div>
              <v-text-field dense height="40px" placeholder="Managing Director" outlined v-model="managingDirector"></v-text-field>
              <div class="pb-1 secondaryColor fsize14">Status</div>
              <v-select v-model="status" outlined dense placeholder="Select the status" :items="['Open','Upcoming','Recently Closed','Recently Listed']" menu-props="offset-y"></v-select>
            </v-col>
            <v-col cols="12" sm="12" md="6" lg="4"></v-col>
          </v-row>
          <div v-if="status != 'Upcoming'">
            <v-divider></v-divider>
            <v-row class="ma-0 mt-6">
              <v-col cols="12" sm="12" md="6" lg="3">
                <span class="fsize14 font-weight-bold primaryColor">Listing Details</span>
              </v-col>
              <v-col cols="12" sm="12" md="6" lg="5">
                <v-row class="d-flex ma-0">
                  <v-col class="pa-0 mr-4">
                    <div class="pb-1 secondaryColor fsize14">Bidding From Date</div>
                    <v-menu v-model="pidFromDate" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="auto">
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field :disabled="status == 'Upcoming'" v-model="computedDateFormatted" outlined persistent-hint placeholder="Enter the Bidding From Date" :rules="status != 'Upcoming' ? fromDateRules : []" append-icon="mdi-calendar" readonly dense v-bind="attrs" v-on="on"></v-text-field>
                      </template>
                      <v-date-picker v-model="eventDate" :show-current="true" no-title @input="pidFromDate = false"></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col class="pa-0">
                    <div class="pb-1 secondaryColor fsize14">Bidding To Date</div>
                    <v-menu v-model="pidToDate" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="auto">
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field :disabled="status == 'Upcoming'" v-model="computedDateFormatted1" outlined persistent-hint placeholder="Enter the Bidding To Date" :rules="status != 'Upcoming' ? toDateRules : []" append-icon="mdi-calendar" readonly dense v-bind="attrs" v-on="on"></v-text-field>
                      </template>
                      <v-date-picker v-model="eventDate1" :show-current="true" no-title @input="pidToDate = false"></v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
                <v-row class="d-flex ma-0">
                  <v-col class="pa-0 mr-4">
                    <div class="pb-1 secondaryColor fsize14">Price Range From</div>
                    <v-text-field :disabled="status == 'Upcoming'" dense height="40px" placeholder="Enter the Price Range From" outlined v-model="priceFrom" :rules="status != 'Upcoming' ? priceFromRules : []">
                    </v-text-field>
                  </v-col>
                  <v-col class="pa-0">
                    <div class="pb-1 secondaryColor fsize14">Price Range To</div>
                    <v-text-field :disabled="status == 'Upcoming'" dense height="40px" placeholder="Enter the Price Range To" outlined v-model="priceTo" :rules="status != 'Upcoming' ? priceToRules : []">
                    </v-text-field>
                  </v-col>
                </v-row>

                <div class="pb-1 secondaryColor fsize14">Issue Size</div>
                <v-text-field :disabled="status == 'Upcoming'" dense height="40px" placeholder="Enter the Issue Size" outlined v-model="issueSize">
                </v-text-field>

                <div class="pb-1 secondaryColor fsize14">Minimum Investment</div>
                <v-text-field :disabled="status == 'Upcoming'" dense height="40px" placeholder="Enter the Minimum Invest " outlined v-model="minInvest" :rules="status != 'Upcoming' ? minInvestRules : []">
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="6" lg="4"></v-col>
            </v-row>
          </div>
        </v-card>

        <div class="mt-5 pa-4 d-flex justify-end">
          <v-btn height="40" width="96" depressed outlined class="text-capitalize" @click="$router.push('/ipo')">Cancel</v-btn>
          <v-btn height="40" width="96" color="primary" depressed class="ml-4 white--text text-capitalize" @click="addOrEditIpo">
            Save
          </v-btn>
        </div>
      </v-form>
    </div>
  </div>
</template>

<script>
import service from "../js/httpservice";
import { mapGetters, mapState } from "vuex";
export default {
  data() {
    return {
      formvalid: true,
      menu: false,
      dialog: false,
      shortName: "",
      longName: "",
      pidFromDate: false,
      pidToDate: false,
      minInvest: "",
      priceFrom: "",
      priceTo: "",
      foundedYear: "",
      issueSize: null,
      aboutCompany: "",
      managingDirector: "",
      parentOrganization: "",
      eventDate: "",
      eventDate1: "",
      status: "",
      ipoId: "0",
      image: null,

      // shortNameRules: [(v) => !!v || "Short name is required"],
      longNameRules: [(v) => !!v || "Name is required"],
      fromDateRules: [(v) => !!v || "Pidding From Date is required"],
      toDateRules: [(v) => !!v || "Pidding To Date is required"],
      minInvestRules: [(v) => !!v || "Minimum Invest is required"],
      priceFromRules: [(v) => !!v || "Price Range From is required"],
      priceToRules: [(v) => !!v || "Price Range To is required"],
    };
  },
  methods: {
    async addOrEditIpo() {
      this.$refs.form.validate();
      let json = {
        ipoName: this.longName,
        bbFromDate: this.status == "Upcoming" ? null : this.eventDate,
        bbToDate: this.status == "Upcoming" ? null : this.eventDate1,
        minInvest: this.status == "Upcoming" ? null : this.minInvest,
        priceRangeFrom: this.status == "Upcoming" ? null : this.priceFrom,
        priceRangeTo: this.status == "Upcoming" ? null : this.priceTo,
        issueSize: this.status == "Upcoming" ? null : this.issueSize,
        aboutCompany: this.aboutCompany,
        parentOrg: this.parentOrganization,
        foundedOn: this.foundedYear,
        mdName: this.managingDirector,
        ipoId: this.ipoId,
        status: this.status,
      };
      if (
        this.status == "Upcoming" ||
        (this.longName &&
          this.computedDateFormatted != "" &&
          this.computedDateFormatted1 != "" &&
          this.minInvest &&
          this.priceFrom &&
          this.priceTo)
      ) {
        await this.$store.dispatch("ipo/insertIpoData", {
          img: this.image ? this.image : null,
          obj: json,
        });
      }
    },

    // reset() {
    //   this.$refs.form.reset();
    // },

    // resetValidation() {
    //   this.$refs.form.resetValidation();
    // },

    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${year}-${month}-${day}`;
    },

    getIpoList(ipoId) {
      service.getIpoData({}).then((resp) => {
        if (resp.status == 200 && resp.data.stat == "Ok") {
          for (let item of resp.data.result) {
            if (ipoId == item.ipoId) {
              this.longName = item.ipoName;
              this.eventDate = item.bbFromDate
                ? item.bbFromDate.split(" ")[0]
                : "";
              this.eventDate1 = item.bbToDate
                ? item.bbToDate.split(" ")[0]
                : "";
              this.minInvest = item.minInvest;
              this.priceFrom = item.priceRangeFrom;
              this.priceTo = item.priceRangeTo;
              this.issueSize = item.issueSize;
              this.aboutCompany = item.aboutCompany;
              this.parentOrganization = item.parentOrg;
              this.managingDirector = item.mdName;
              this.foundedYear = item.foundedOn;
              this.status = item.status;
            }
          }
        }
      });
    },
  },
  computed: {
    computedDateFormatted() {
      return this.formatDate(this.eventDate);
    },
    computedDateFormatted1() {
      return this.formatDate(this.eventDate1);
    },
    ...mapGetters("ipo", ["getIpoData"]),
    ...mapState("ipo", ["ipoData"]),
  },
  mounted() {
    var checkId = JSON.parse(localStorage.getItem("addOrEditIpo"));
    !!checkId && checkId != 0 ? this.getIpoList(checkId) : "";
    !!checkId ? (this.ipoId = checkId) : "";
  },
};
</script>

<style>
</style>