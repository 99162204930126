<template>
  <div class="pa-4">
    <v-row class="ma-0 pa-4 align-center">
      <v-col cols="3" class="pa-0">
        <div class="d-flex align-center">
          <div class="fsize20 font-weight-bold primaryColor">Sector</div>
        <v-progress-circular class="ml-2" indeterminate v-if="loading" size="18" :width="2" color="black"></v-progress-circular>
        </div>
      </v-col>
      <v-col cols="6" class="pa-0" style="height: 40px">
        <v-text-field single-line dense outlined height="32px" v-model="search" prepend-inner-icon="mdi-magnify" placeholder="Search"></v-text-field>
      </v-col>
      <v-col cols="3" class="pa-0 d-flex justify-end">
        <v-btn min-width="155px" color="#157AFF" depressed @click="addOrEditSector('0')" class="text-capitalize fsize14 white--text">Add Sector</v-btn>
      </v-col>
    </v-row>
    <v-data-table v-if="sectorList.length != 0 && !loading" :headers="headers" v-bind:items="sectorList" :search="search" :value="sectorList" class="elevation-1 my-2 cursor" id="table" item-key="name" single-select>
      <template v-slot:item="props">
        <tr>
          <td @click="sectorDetails(props.item.sectorId)">{{ props.index + 1 }}</td>
          <td @click="sectorDetails(props.item.sectorId)">{{ props.item.imageUrl }}</td>
          <td @click="sectorDetails(props.item.sectorId)">{{ props.item.sectorName }}</td>
          <td @click="sectorDetails(props.item.sectorId)" class="text-center">{{ props.item.sectorId }}</td>
          <!-- <td class="text-center">
            <v-icon @click="deleteSector(props.item.sectorId)"> mdi-delete </v-icon>
          </td> -->
        </tr>
      </template>
    </v-data-table>

    <div v-else-if="!loading">
      <div class="text-center pa-5">
        <v-icon class="display-2 pb-4">mdi-thought-bubble-outline</v-icon>
        <div class="">Data Not Found</div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      headers: [
        { text: "S.NO", align: "start", sortable: false },
        { text: "Image", value: "imageUrl", sortable: false },
        { text: "Sector Name", value: "sectorName", sortable: false },
        {
          text: "Sector Id",
          align: "center",
          value: "sectorId",
          sortable: false,
        },
        // {
        //   text: "Actions",
        //   align: "center",
        //   value: "sectorId",
        //   sortable: false,
        // },
      ],
      search: "",
    };
  },
  computed: {
    ...mapGetters({
      loading: "getLoading",
    }),
    ...mapGetters("sector", {
      sectorList: "getSectorData",
    }),
  },
  methods: {
    addOrEditSector(id) {
      this.$router.push("/addOrEditSector");
    },
    deleteSector(id) {
      this.$store.dispatch("sector/deleteSector", { sectorId: id });
    },
    sectorDetails(sectorId) {
      this.$router.push("/sectorDetails");
      localStorage.setItem("sectorId", sectorId);
    },
  },
  created() {
    this.$store.dispatch("sector/getSector");
  },
};
</script>