<template>
  <div>
    <v-row class="ma-0 pa-4 align-center">
      <v-col cols="2" class="pa-0">
        <h3 width="100" height="24" class=".text-lg-h6">Events</h3>
      </v-col>
      <v-col class="pa-0" cols="1"></v-col>
      <v-col cols="6" class="pa-0" style="height: 40px">
        <v-text-field single-line dense outlined v-model="search" height="32px" prepend-inner-icon="mdi-magnify" placeholder="Search"></v-text-field>
      </v-col>
      <v-col class="pa-0" cols="1"></v-col>

      <v-col cols="2" class="pa-0 d-flex justify-end">
        <v-btn depressed color="align-center justify-center  text-none pa-0 bgr_clr_btn_save " min-width="155px" @click="addOrEditEvent('0')">
          Add Event
        </v-btn>
      </v-col>
    </v-row>
    <v-card class="ma-4">
      <v-data-table v-if="evenList.length != 0" :headers="headers" v-bind:items="evenList" :search="search" :value="evenList" class="elevation-1 my-5" id="table" item-key="name" single-select>
        <template v-slot:item="props">
          <tr>

            <td>{{ props.index + 1 }}</td>
            <td>{{ props.item.eventName }}</td>
            <td>{{ props.item.geography }}</td>
            <td>{{ props.item.sector }}
            </td>
            <td>{{ props.item.actualOutcome }}</td>
            <td>{{ props.item.expectedOutcome }}</td>
            <td>{{ props.item.previousOutcome }}</td>
            <td>{{ props.item.eventDescription }}</td>
            <td>{{ props.item.indiaDate }}</td>
            <td>{{ props.item.indiaTime }}</td>
            <td>{{ props.item.significance }}</td>

            <td>
              <v-icon medium @click="addOrEditEvent(props.item.eventId)"> mdi-pencil </v-icon>
            </td>
            <td>
              <v-icon medium @click="deleteEvent(props.item.eventId)"> mdi-delete </v-icon>
            </td>
          </tr>

        </template>
      </v-data-table>
      <div v-else>
        <div class="text-center pa-5">
          <v-icon class="display-2 pb-4">mdi-thought-bubble-outline</v-icon>
          <div class="">Data Not Found</div>
        </div>
      </div>
    </v-card>
  </div>
</template>

<script>
import service from "../js/httpservice";
export default {
  data() {
    return {
      evenList: [],
      search: "",
      headers: [
        {
          text: "S.NO",
          align: "start",
          sortable: false,
        },
        { text: "Event Name", value: "eventName", sortable: false },
        { text: "geography", value: "geography", sortable: false },
        { text: "sector", value: "sector", sortable: false },
        { text: "Actual Outcome", value: "actualOutcome", sortable: false },
        { text: "Expected Outcome", value: "expectedOutcome", sortable: false },
        { text: "Previous Outcome", value: "previousOutcome", sortable: false },
        { text: "Description", value: "eventDescription", sortable: false },
        { text: "Date", value: "indiaDate", sortable: false },
        { text: "Time", value: "indiaTime", sortable: false },
        { text: "Significance", value: "significance", sortable: false },
        { text: "", value: "", sortable: false },
        { text: "", value: "", sortable: false },
      ],
    };
  },
  methods: {
    getList() {
      service.getEventList({}).then((resp) => {
        if (resp.status == 200 && resp.data.stat == "Ok") {
          this.evenList = resp.data.result;
        } else {
          this.evenList = [];
        }
      });
    },
    addOrEditEvent(key) {
      localStorage.setItem("addOrEditEvent", JSON.stringify(key));
      this.$router.push("/newOrEditEvents");
    },
    deleteEvent(key) {
      let jsonobj = {
        eventId: key,
      };
      service.deleteEvent(jsonobj).then((resp) => {
        if (resp.status == 200) {
          this.getList();
        }
      });
    },
  },
  mounted() {
    this.getList();
  },
};
</script>

<style>
</style>